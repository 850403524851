import React from 'react';
import {
  HeaderLanguageSelector,
} from '@/components/landing/LandingHeader/components/HeaderLanguageSelector';
import {
  HeaderProfileLink,
} from '@/components/landing/LandingHeader/components/HeaderProfileLink';
import { useSubDomainContext } from '@/controllers/subDomain/subDomain.hooks/useSubDomainContext';
import { i18nIsGMDomain } from '@/middleware/i18n/i18n.utils/i18n.isGMDomain';

export const HeaderProfileActions = () => {
  const { subDomain } = useSubDomainContext();

  return (
    <div className="flex-container">
      {!i18nIsGMDomain(subDomain) && (
        <div className="mr-8 medium-mr-4 small-mr-0">
          <HeaderLanguageSelector />
        </div>
      )}

      <div className="show-for-medium">
        <HeaderProfileLink />
      </div>
    </div>
  );
};
