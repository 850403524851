import { useEffect } from 'react';
import { Router } from '@/middleware/i18n';

interface UseRouteChangeCallback {
  (callback: () => void): void;
}
export const useRouteChangeCallback: UseRouteChangeCallback = (callback) => {
  useEffect(() => {
    const routerHandler = () => {
      callback();
    };

    Router.events.on('routeChangeStart', routerHandler);

    return () => {
      Router.events.off('routeChangeStart', routerHandler);
    };
  }, [callback]);
};
